<template>
  <div>
    <big-title>
      Queremos <span>conocerte</span>.
    </big-title>

    <!-- SECCIONES -->

    <vx-card v-if="isMounted && !failed">
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-container">
          <vs-tab :label="group.title" icon-pack="feather" :icon="group.icon" v-for="(group, i) in this.sectionGroups" :key="i">
            <div class="tab-text">             
              <vx-card v-for="section in group.sections" :key="section.data.id" :title="section.data.public_name" class="mb-5">
                <p class="mb-4" v-if="section.data.public_description != null">{{section.data.public_description}}</p>
                <div class="vx-row">
                  <template v-for="f in section.data.fields">
                    <!-- Campos automaticos -->
                    <form-field 
                      :ref="group.slug"
                      v-if="isAutoFormField(f)"
                      :key="f.id"
                      :f="f"
                      :dataContainer="getContainerForField(section, f)"
                      :collectionsRoot="collections"
                      :onChangeReceptor="onFieldChange"
                      :evaluator="evaluateCondition" />
                    <!-- Campos especiales -->
                    <div v-else-if="f.db_field == 'sof_types_selector'" class="vx-col lg:w-1/2 md:w-3/4 sm:w-full w-full mb-base" :key="f.id" >
                      <input type="hidden" value="someData" name="sof_types_selector" v-validate="'required'" >
                      <vs-list class="supplier_tools_list">               
                          <vx-tooltip
                            v-if="f.indication"
                            :title="f.field_name"
                            color="primary"
                            style="float:right;cursor:pointer;margin-top:-8px"
                            :text="f.indication">                           
                            <vs-chip color="primary">?</vs-chip>
                          </vx-tooltip>
                          <vs-list-header title="Origen de los recursos"/>
                        
                          <vs-list-item 
                            v-for="sof_type in applicantSofTypes"
                            :key="sof_type.type"
                            :title="sof_type.name"
                            subtitle="" >
                            <div class="inline-tools">
                              <vs-input 
                                v-model="sof_type.sof_other"
                                v-validate="'required'"
                                placeholder="Ingrese la descripción"
                                v-if="sof_type.for_other && sof_type.checked"
                                name="other_sof_types"
                                style="margin-right:20px;"
                                :danger="(errors.has('other_sof_types'))"
                                :danger-text="errors.first('other_sof_types')"
                                :success="fields['other_sof_types'] && fields['other_sof_types'].valid"
                                />
                              <vs-checkbox v-model="sof_type.checked" color="success"/>
                            </div>
                          </vs-list-item>
                      </vs-list>
                    </div>

                    <div v-else-if="f.db_field == 'activities_selector'" class="vx-col lg:w-1/2 md:w-3/4 sm:w-full w-full mb-base" :key="f.id" >
                      <input type="hidden" value="someData" name="activities_selector" v-validate="'required'" >
                      <vs-list class="supplier_tools_list">               
                          <vx-tooltip
                            v-if="f.indication"
                            :title="f.field_name"
                            color="primary"
                            style="float:right;cursor:pointer;margin-top:-8px"
                            :text="f.indication">                           
                            <vs-chip color="primary">?</vs-chip>
                          </vx-tooltip>
                          <vs-list-header title="Elige las actividades vulnerables en las cuales estás relacionado"/>
                        
                          <vs-list-item 
                            v-for="act in applicantActivities"
                            :key="act.id"
                            :title="act.name"
                            subtitle="" >
                            <div class="inline-tools">
                              <vs-checkbox v-model="act.checked" color="success"/>
                            </div>
                          </vs-list-item>
                      </vs-list>
                    </div>
                  </template>
                </div>
              </vx-card>

              <vx-card v-if="show_peps_pf" title="Personas Políticamente Expuestas (PEP)" class="mb-5">
                <applicant-peps-control-pf
                ref="applicant_peps_component_pf"
                :relations="collections.allRelations"
                :applicant_id = 'ApplicantId' />
              </vx-card>

              <vx-card v-if="show_peps_pm" title="Personas Políticamente Expuestas (PEP)" class="mb-5">
                <applicant-peps-control-pm
                ref="applicant_peps_component_pm"
                :applicant_id = 'ApplicantId' />
              </vx-card>
              
              <!-- Save Button -->
              <div class="vx-row">
                <div class="vx-col w-full">
                  <div class="flex flex-wrap items-center justify-end">
                    <vs-button class="ml-auto mt-2" @click="saveTabChanges(group.slug)">Guardar cambios</vs-button>                    
                  </div>
                </div>
              </div>
            </div>
          </vs-tab>
          <vs-tab :disabled="disable_owner_tab" label="Propietario Real" @click="$router.push({ name: 'kycSolicitantePropietario' })">
          </vs-tab>
          <vs-tab :disabled="disable_provider_tab" label="Clasificación de persona" @click="$router.push({ name: 'kycSolicitantePersona' })">
          </vs-tab>
          <vs-tab :disabled="disable_provider_tab" label="Proveedor de recursos" @click="personTypeProvider()">
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
    <load-error v-if="failed" />
  </div>
</template>



<script>
import { mapState } from 'vuex';
import inputHelper from '../../components/mixins/inputHelper';
import dateHelper from '../../components/mixins/dateHelper';
import formHelper from '../../components/mixins/formHelper';
import FormField from '@components/forms/FormField.vue';
import ApplicantPepsControlPf from '@components/applicant/ApplicantPepsControlPf.vue';
import ApplicantPepsControlPm from '@components/applicant/ApplicantPepsControlPm.vue';

const pfaeTabsDefs = [
  { slug: "seccion1", title: "Queremos conocerte",  icon: null, sections:[63] },
];

const pmTabsDefs = [
  { slug: "seccion1", title: "Queremos conocerte",  icon: null, sections:[90] }
];

const sectionsToContainers = [
  { id: 63, containers: [
    {db:'personal_profiles', path:'user.personal'},
    {db:'pld_user_peps', path:'user.pld_user_peps', arrayDefs:{}},
    {db:'pld_user_sofs', path:'user.pld_user_sofs', arrayDefs:{}},
    {db:'pld_user_vulnerable_activities', path:'user.vulnerable_activities', arrayDefs:{}},
    {db:'pld_initial_transactional_profiles', path:'user.pld_initial_transactional_profile'}] },
  { id: 90, containers: [
    {db:'business_profiles', path:'user.business'},
    {db:'pld_user_peps', path:'user.pld_user_peps', arrayDefs:{}},
    {db:'pld_user_sofs', path:'user.pld_user_sofs', arrayDefs:{}},
    {db:'pld_user_vulnerable_activities', path:'user.vulnerable_activities', arrayDefs:{}},
    {db:'pld_initial_transactional_profiles', path:'user.pld_initial_transactional_profile'}] }
];

const requiredObjects = [
  'user.personal',
  'user.business',
  'user.pld_user_sofs',
  'user.pld_user_peps',
  'user.pld_real_owner',
  'user.vulnerable_activities',
  'user.pld_initial_transactional_profile',
  'user.pld_resource_provider',
  // 'user.pld_initial_transactional_profile.pld_initial_transactional_profile_funds_destinations'
];

const arrayPaths = ['user.pld_user_sofs','user.vulnerable_activities'];

export default {
  mixins: [formHelper, inputHelper, dateHelper],
  data(){
    return {
      moralTypeValue: 0,
      pfaeTypeValue: 1,
      flow_id_pfae: 7,
      flow_id_pm: 8,

      disable_owner_tab: true,
      disable_provider_tab: true,

      show_peps_pf: false,
      show_peps_pm: false,
      loading: false,
      isMounted: false,
      isRefreshing: false,
      errorMssg: '',
      successMssg: '',
      failed: false,
      flow: {},
      allSections: [],
      sectionGroups: [],
      sofTypesToShow:[],
      applicantSofTypes: [],
      applicantActivities: [],
      tabs: [],

      clientType: null,
      isMoral: false,
      isPfae: false,
      activeTab: 0,

      collections: {
        allSofTypes: [],
        allActivities: [],
        allAverageDeposits: [],
        allAverageWithdraws: [],
        allPersonTypes: [],
        allRelations: [],
        cities: [],
        states: [],
        countries: [],
        // allFundsDestinations: [],
      },      

      // objeto principal
      base: {
        user: {
          personal: {},
          business: {},
          vulnerable_activities:[],
          pld_user_sofs:[],
          pld_user_peps:[],
          pld_real_owner:{},
          pld_initial_transactional_profile: {},
          pld_resource_provider: {
            personal_profile: {
              address: {
                country_id: 0
              }
            },
          }
        }
      },
      // fin objeto principal

      // objetos para actualizaciones
      basePayload: {},
      validatedFields: [],
    }
  },
  components: {
    FormField,
    ApplicantPepsControlPf,
    ApplicantPepsControlPm
  },
  computed: {
    ...mapState("auth", ["user"]),
    personal(){
      return this.base.user.personal;
    },
    business(){
      return this.base.user.business;
    },
    resource_provider(){
      return this.base.user.pld_resource_provider;
    },
    pld_user_peps(){
      return this.base.user.pld_user_peps;
    },
    pld_real_owner(){
      return this.base.user.pld_real_owner;
    },
    pld_initial_transactional_profile(){
      return this.base.user.pld_initial_transactional_profile;
    },
    should_fill_person_type_1(){
      return this.base.user.pld_resource_provider.person_type == 1;
    }
  },
  async mounted() {
    this.loading = true;
    this.isMounted = false;
    await this.getCollections();
    await this.getApplicantData();
    this.setPersonTypeInfo();
    await this.setCustomIntegrations();
    await this.getFormInfo();
    this.setSectionGroups();
    this.isMounted = true;
    this.loading = false;
  },
  watch: {
    loading : function(newVal, oldVal){
      if(newVal == true){
         this.$vs.loading();
      }
      else {
        this.$vs.loading.close();
      }
    }
  },
  methods: {
    async reloadFormInfo(){
      this.isRefreshing = true;
      await this.getApplicantData();
      this.setPersonTypeInfo();
      await this.setCustomIntegrations();
      this.reloadSectionGroups();
      this.isRefreshing = false;
    },
    async saveTabChanges(gslug){
      console.log("saving => " + gslug);
      let result = await this.runValidations(gslug);
      if(!result){
       this.missingFieldsNotif();
      }
      else {
        this.loading = true;

        //guardar solo la informacion que se ha modificado
        this.basePayload = this.collectFormData(gslug);
        this.basePayload.id = this.base.id;
        this.collectCollectionsData();
        if(this.objectIsEmpty(this.basePayload)){
          //this.notFieldsToUpdate();
          this.saveSuccessNotif();
          this.loading = false;
          return;
        }
        
        try {
          // ejecutar guardado
          let response = await axios.put(`/api/v1/applicant/${this.ApplicantId}/deepUpdateKyc`, this.basePayload);
          console.log(response.data);
          // solicitar la informacion actualizada del modelo
          await this.reloadFormInfo();
          this.saveSuccessNotif();          
        }
        catch (error) {
          this.failedOperationNotif(null, null);          
        }
        
        this.loading = false;        
      }
    },
    async runValidations(gslug){
       // validar componentes tipo FormField
      let res1 = await this.validateFormFields(gslug);
      if(!res1){
        return false;
      }
    
      // validar inputs instanciados en la vista
      let res2 = await this.$validator.validateAll();
      if(!res2){
        return false;
      }
    
      // inspeccionar los campos para ejecutar validaciones especiales en campos tipo FormField
      let spValidation = await this.checkFormFieldsSpecialValidations(gslug);
      if(!spValidation){
        return false;
      }

      // inspeccionar los campos agregados directo en el componente para ejecutar validaciones especiales
      let group = this.sectionGroups.find(g => g.slug == gslug);
      let sp_failed = false;
      group.sections.forEach(s => {
        s.data.fields.filter(f => f.special_definition == 1).forEach(f => {
          // considerar los campos habilitados (visibles), con validaciones especiales
          if(this.fields[f.fname] && f.special_validation != null && f.special_validation.method != null) {
            let res = this[f.special_validation.method]();

            if(!res){
              this.errors.add({
                field: f.fname,
                msg: f.special_validation.error
              });
              this.missingFieldsNotif(f.field_name, f.special_validation.error, 10000);
              sp_failed = true;
            }
          }
        });
      });
      
      if (this.validate_user_peps() == false)
      {
        this.errors.add({
          field: "PEPs",
          msg: "Debes agregar por lo menos una PEP"
        });
        this.missingFieldsNotif("PEPs", "Debes agregar por lo menos una PEP", 10000);
        sp_failed = true;
      }

      if(sp_failed){
        return false;
      }

      // retornar true si todas las validaciones fueron positivas
      return true;
    },
    collectFormData(gslug){
      let payload = {};
      let group = this.sectionGroups.find(g => g.slug == gslug);
      group.sections.forEach(section => {
        section.containers.forEach(cc => {
          let fields = this.validatedFields.filter(vf => vf.db == cc.db);
          if(fields.length < 1){
            return;
          }

          if(cc.path == "base"){
            fields.forEach(f => {
              payload[f.fname] = cc.container[f.fname];
            });
          }
          else {
            let obj = null;
            if(arrayPaths.includes(cc.path)){
              // se integran todos los objetos de la coleccion
              obj = this.nestedFieldValue(this.base, cc.path);
            }
            else {
              obj = {id: cc.container.id};
              fields.forEach(f => {
                obj[f.fname] = cc.container[f.fname];
              });
            }
            // ajuste para objetos tipo array
            this.setDeepObjectWithIds(payload, this.base, cc.path, obj);
          }          
        });
      });
      return payload;
    },
    async validateFormFields(refGroup){
      let allValid = true;
      this.validatedFields = [];
      let grefs = this.$refs[refGroup];
      for(let p in grefs){
        let r = await this.$refs[refGroup][p].checkForValidDirty();
        if(r.valid == false){
          allValid = false;
        }
        else if(r.dirty == true) {
          this.validatedFields.push(r);
        }
      }
      return allValid;
    },
    async checkFormFieldsSpecialValidations(refGroup){
      let spvalid = true;
      let grefs = this.$refs[refGroup];
      for(let p in grefs){
        let sp = this.$refs[refGroup][p].specialValidation();
        if(sp != null){
          let res = this[sp.method]();
          let n = this.$refs[refGroup][p].fieldName;
          if(!res){
            spvalid = false;
            this.errors.add({
              field: n,
              msg: sp.error
            });
            this.missingFieldsNotif(n, sp.error, 10000);
          }
        }
      }
      
      return spvalid;
    },
    collectCollectionsData(){
       // Soft Types
        let t = [];
        this.applicantSofTypes.forEach(f => {
          let item = {};
          item.type = f.type;
          item.sof_other = f.sof_other;
          item.checked = f.checked;
          t.push(item);
        });
        this.basePayload.sof_types = t;

         // Activities
        let c = [];
        this.applicantActivities.forEach(f => {
          let item = {};
          item.id = f.id;
          item.checked = f.checked;
          c.push(item);
        });
        this.basePayload.vulnerable_activities = c;
    },
    /* on change receptor */
    onFieldChange(method){
      if(method != null && method in this){
        console.log("onFieldChange => " + method)
        this[method]();
      }
    },
    /* evaluator */
    evaluateCondition(condition){
      if(condition != null && condition in this){
        let ss = this[condition];
        console.log("evaluateCondition => " + condition + ", " + ss);
        return ss;
      }      
    },
    async setCustomIntegrations(){
        if(this.isMoral){
          this.sofTypesToShow =  this.collections.allSofTypes.filter(p => p.kind != "PF");
        }else{
          this.sofTypesToShow =  this.collections.allSofTypes.filter(p => p.kind != "PM");
        }

       // Origen de los recursos
        let t = [];
        this.sofTypesToShow.forEach(f => {
          let ss = this.base.user.pld_user_sofs.find(x => x.sof_type == f.type);
          let item = {};
          item.name = f.name;
          item.type = f.type;
          item.checked = ss != null;
          item.for_other = f.kind == "NA" ? true : false;;
          item.sof_other = ss != null ? ss.sof_other : null;
          t.push(item);
        });
        this.applicantSofTypes = t;

       // Actividades vulnerables
        let a = [];
        this.collections.allActivities.forEach(f => {
          let ss = this.base.user.vulnerable_activities.find(x => x.id == f.id);
          let item = {};
          item.name = f.name;
          item.id = f.id;
          item.checked = ss != null;
          a.push(item);
        });
        this.applicantActivities = a;
    },
    setSectionGroups(){
      this.tabs.forEach(t => {
        let group = { title: t.title, icon: t.icon, slug: t.slug };
        let sections = [];
        
        t.sections.forEach(ss => {
          let s = this.allSections.find(f => f.id == ss);
          if(s != null){
            let sb = { data: s, containers: this.getContainersForSection(s.id) };
            sections.push(sb);
          }
          else {
            this.warn("Section " + ss + " not found");
          }
        })
        group.sections = sections;
        this.sectionGroups.push(group);
      });
    },
    reloadSectionGroups(){
      this.sectionGroups.forEach(group => {
        group.sections.forEach(s => {
          s.containers = this.getContainersForSection(s.data.id);
        })
      });
    },
    getContainerForField(section, f){
      let c = section.containers.find(sc => sc.db == f.db_table);
      if(!c){
        this.warn(`Container not found for db [${f.fname}]: ${f.db_table}`);
        return null;
      }
      else {
        return c.container;
      }
    },
    getContainersForSection(section_id){
      let c = sectionsToContainers.find(f => f.id == section_id);
      if(!c){
        this.warn("Missing containers definition for section " + section_id);
        return null;
      }
      else {
        let sectionContainers = [];
        c.containers.forEach(cc => {
          let con = this.setContainerFromDef(cc);
          sectionContainers.push({db: cc.db, path: cc.path, container: con});
        })
        return sectionContainers;
      }
    },
    setContainerFromDef(c){
      let container = this.nestedFieldValue(this.base, c.path);
      if(Array.isArray(container)){
        let ac = null;
        if(c.arrayDefs){
          if(c.arrayDefs.getFirst == true){
            ac = container[0];
          }
          else if(c.arrayDefs.eval){
            // llamado en funciones de secciones
            ac = this[c.arrayDefs.eval](container);
          }
        }
        if(!ac){
          if(c.arrayDefs.onNull){ ac = this[c.arrayDefs.onNull](); }
          else { ac = {} }
          container.push(ac);
        }
        return ac;
      }
      else {
        return container;
      }
    },
    async getApplicantData(){
      try{
        // ?filter[status]=3 // ejemplo de filtro
        let params = "with[]=" + requiredObjects.join("&with[]=");
        let response = await axios.get(`/api/v1/applicant/${this.ApplicantId}?${params}`);
        this.base = response.data;
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },    
    async getFormInfo(){
      try{
        if (this.isMoral) {
          await this.getSectionInfo(90);
        }else{
          await this.getSectionInfo(63);
        }
        this.allSections.forEach(s => {          
          s.fields.forEach(f => {
            this.formFieldSetter(f, this, true);
          });
        });
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    async getSectionInfo(id){
      try{
        let response = await axios.get("/api/v1/forms/getSectionFields/" + id);
        this.allSections.push(response.data.section);
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    setPersonTypeInfo(){
      this.applicantType = this.base.user.person_type;
      this.isMoral = this.applicantType == this.moralTypeValue;
      this.isPfae = this.applicantType == this.pfaeTypeValue;
      this.tabs = this.isMoral ? pmTabsDefs : pfaeTabsDefs;
      if(this.isMoral){
        if(this.pld_real_owner.personal_profile_id == null){
            this.business.real_owner = 1;
        }
        else{
            this.business.real_owner = 0;
        }
        this.business.familiar_required = 0;
        if(this.pld_user_peps.length != 0){
          this.show_peps_pm = true;
          this.business.familiar_required = 1;
        }
      }else{
        if(this.pld_real_owner.personal_profile_id == null){
            this.personal.real_owner = 1;
        }
        else{
            this.personal.real_owner = 0;
        }
        this.personal.familiar_required = 0;
        if(this.pld_user_peps.length != 0){
          this.show_peps_pf = true;
          this.personal.familiar_required = 1;
        }
      }

      if (this.pld_real_owner.person_type != null) {
        this.disable_owner_tab = false;
      }
      else{
        this.disable_owner_tab = true;
      }

      if (this.resource_provider.person_type != null) {
        this.disable_provider_tab = false;
      }
      else{
        this.disable_provider_tab = true;
      }
    },
    async getCollections(){
      try {        
        let response2 = await axios.get('/api/v1/forms/getVulnerableActivities');
        this.collections.allActivities = response2.data;

        let response4 = await axios.get('/api/v1/forms/getAverageWithdraws');
        this.collections.allAverageWithdraws = response4.data;

        let response5 = await axios.get('/api/v1/forms/getPersonTypes');
        this.collections.allPersonTypes = response5.data;
        
        let response6 = await axios.get('/api/v1/forms/getRelations');
        this.collections.allRelations = response6.data;
      } catch (e) {
        this.warn(e);
        this.failed = true;
      }
    },
    /* on_change methods */
    peps_required_changed(){
      if (this.isMoral) {
        if (this.business.familiar_required == 1) {
          this.show_peps_pm = true;
        }else{
          this.show_peps_pm = false;
        }
      }
      else{
        if (this.personal.familiar_required == 1) {
          this.show_peps_pf = true;
        }else{
          this.show_peps_pf = false;
        }
      }
    },
    validate_sof_types(){
      return this.applicantSofTypes.filter(f => f.checked == true).length > 0;
    },
    validate_activities(){
      return this.applicantActivities.filter(f => f.checked == true).length > 0;
    },
    validate_user_peps(){
      if (this.show_peps_pm && this.$refs.applicant_peps_component_pm[0] != null) {
        return this.$refs.applicant_peps_component_pm[0].validate();
      }
      else{
        if (this.show_peps_pf && this.$refs.applicant_peps_component_pf[0] != null) {
          return this.$refs.applicant_peps_component_pf[0].validate();            
        }
      }
      return true;
    },
    personTypeProvider(){
      this.$router.push({ name: 'kycSolicitanteProveedor' });
    }
  }
}
</script>