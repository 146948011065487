<template>
  <div class="vx-col w-full mb-5" >
    <h6>Personas Políticamente Expuestas (PEP)</h6>
    <vs-button color="primary" type="border" icon-pack="feather" class="mb-4 mt-3"  @click="addPep()">Agregar Persona</vs-button>
      <vs-table stripe :data="peps" noDataText="No se han generado registros">
        <template slot="thead">
          <vs-th class="bold">Nombre completo de la persona que desempeña o desempeñó el cargo público</vs-th>
          <vs-th class="bold">Puesto desempeñado</vs-th>
          <vs-th class="bold">ACCIONES</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">

            <vs-td :data="data[indextr].pep_complete_name">
              {{ data[indextr].pep_complete_name }}
            </vs-td>

            <vs-td :data="data[indextr].pep_position">
              {{ data[indextr].pep_position }}
            </vs-td>

            <vs-td :data="data[indextr].id">
              <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" class="ml-6" @click.stop="removeItemRequest(data[indextr])" />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-popup
        title="Agregar persona"
        :active.sync="showFormPrompt">
        <div class="con-exemple-prompt">
          <vs-alert v-if="onError" class="h-full mb-5" color="danger">
            <span>{{ errorMssg }}</span>
          </vs-alert>
          <vs-alert v-if="onSuccess" class="h-full mb-5" color="success">
            <span>{{ successMssg }}</span>
          </vs-alert>

          <p class="mb-5 bold">{{formPromptIndication}}</p>

          <vs-input
            class="w-full mb-4" 
            label="Nombre completo de la persona que desempeña o desempeñó el cargo público"
            placeholder="Requerido"
            v-validate="'required'"
            data-vv-name="pep_complete_name"
            :danger="hasError('pep_complete_name')"
            :danger-text="errorText('pep_complete_name')"
            :success="isSuccess('pep_complete_name')"
            v-model.lazy="pep_complete_name" />  

          <vs-input
            class="w-full mb-4" 
            label="Puesto desempeñado"
            placeholder="Requerido"
            v-validate="'required'"
            data-vv-name="pep_position"
            :danger="hasError('pep_position')"
            :danger-text="errorText('pep_position')"
            :success="isSuccess('pep_position')"
            v-model.lazy="pep_position" />  

          <vs-button :disabled="!completeForm || $isLoading" @click="savePep" color="primary" class="mr-5 mt-2">Agregar</vs-button>
          <vs-button @click="closeFormPrompt" :disabled="$isLoading" type="flat" color="primary">Cerrar</vs-button>
            
        </div>
      </vs-popup>

    </div>

</template>

<script>
import inputHelper from "@mixins/inputHelper"
const indicationDef = "Ingresa los datos solicitados.";
const errorMssgDef = "Ha ocurrido un error al agregar el registro, intente más tarde."
const successMssgDef = "El registro ha sido agregado exitosamente"
const statePresenceVal = 0;
const cityPresenceVal = 1;

export default {
  name: 'applicant-peps-control-pm',
  mixins: [inputHelper],
  props: {
    // f: { type: Object, required: true },
    applicant_id: { type: [String, Number], required: true },
  },
  data(){
    return {
      pep_complete_name: null,
      pep_position: null,

      peps: [],

      showFormPrompt: false,
      selectedPep: null,

      onError: false,
      onSuccess: false,
      errorMssg: errorMssgDef,
      successMssg: successMssgDef,
      formPromptIndication: indicationDef,
    }
  },
  mounted: async function(){
    await this.loadSavedPeps();
  },
  computed: {
    completeForm(){
      return this.pep_complete_name != null && this.pep_position != null;
    }
  },
  methods: {
    async loadSavedPeps(){
      try {
        let res = await axios.get(`/api/v1/applicant/${this.$props.applicant_id}/getPeps`);
        let collection = res.data;
        this.peps = [];

        collection.forEach(f => {
          let tt = { id: f.id, pep_complete_name: f.pep_complete_name, pep_position: f.pep_position};
          this.peps.push(tt);
        });
      }
      catch (e) {
        this.warn(e);
      }      
    },
    removeItemRequest(pep){
      this.selectedPep = pep;

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Eliminar registro',
        text: `¿Realmente desea eliminar el registro?`,
        acceptText: "Eliminar",
        cancelText: 'Cancelar',
        accept: this.doRemoveItem
      });
    },
    async doRemoveItem(){
      this.showLoading(true);
      try {
        let payload = {pep_id: this.selectedPep.id};
        await axios.post(`/api/v1/applicant/${this.$props.applicant_id}/removePep`, payload);
        await this.loadSavedPeps();
      }
      catch (error) {
        this.warn(error);
      }
      this.showLoading(false);
    },
    async savePep(){
      this.onSuccess = false;
      this.onError = false;
      
      this.showLoading(true);
      try {        
        let payload = { pep_complete_name: this.pep_complete_name, pep_position: this.pep_position};
        await axios.post(`/api/v1/applicant/${this.$props.applicant_id}/insertPep`, payload);
        this.onSuccess = true;
        await this.loadSavedPeps();
        this.closeFormPrompt();
      }
      catch (error) {
        this.warn(error);
        this.onError = true;
      }
      this.showLoading(false);
    },
    addPep(){
      this.resetForm();
      this.showFormPrompt = true;
    },
    resetForm(){
        this.pep_complete_name= null;
        this.pep_position= null;
        this.presence = null;
        this.onError = null;
        this.onSuccess = null;      
        this.sent = null;
    },
    closeFormPrompt() {
      this.showFormPrompt = false;
      this.resetForm();
    },
    hasError(val){
      return this.errors.has(val);
    },
    errorText(val){
      return this.errors.first(val);
    },
    isSuccess(val){
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);      
    },

    /* check validation */
    validate(){
      return this.peps.length >= 1;
    }
  }
}
</script>